import BaseModel from "@/models/base-model.js";

export default class CustomerCategory extends BaseModel {
  fields = {
    name: {
      type: "text",
      title: "Nama",
      label: "nama",
      minLen: 3,
      validationRules: {
        required: true,
        minLength: true,
      },
    },
    sort: {
      type: "text",
      title: "Urutan",
      label: "sort",
      validationRules: {
        required: false,
        minLength: false,
      },
    },
    description: {
      type: "textarea",
      title: "Deskripsi",
      label: "description",
    },
  };
  endpoint = process.env.VUE_APP_API_BASE_URL + "customer-categories";
}
