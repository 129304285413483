<script>
import { convertDateTime } from "@/_helpers/index";
export default {
  props: {
    columns: {
      type: [String, Object, Array],
      required: true,
    },
    data: {
      type: [Object, Array],
      required: true,
    },
    textDefault: {
      type: Boolean,
      required: false,
    },
  },
  watch: {},
  computed: {},
  mounted() {},
  data() {
    return {
      isLoading: false,
      setDataValue: this.value,
    };
  },
  methods: {
    formattedDate(value) {
      return convertDateTime(value);
    },
    getValue(obj, path) {
      return path.split(".").reduce((o, k) => (o || {})[k], obj);
    },
  },
};
</script>
<template>
  <div class="m-3">
    <b-row>
      <b-col
        v-for="(column, index) in columns"
        :key="index"
        md="4"
        sm="6"
        class="my-3"
      >
        <label class="text-bold text-secondary mb-1">{{ column.label || "-" }}</label>
        <div class="fs-16-custom"
          v-if="column.value === 'updated_at' || column.value === 'created_at'"
        >
          {{ data[column.value] ? formattedDate(data[column.value]) : "-" }}
        </div>
        <div v-else-if="column.textDefault" class="fs-16-custom">
          {{
            column.value.includes(".")
              ? getColumnValue(data, column.value)
              : data[column.value] || "-"
          }}
        </div>
        <div v-else-if="column.useBoolean" class="fs-16-custom">
          {{ column.useBoolean[data[column.value]] || "-" }}
        </div>
        <template v-else-if="column.color">
          <div
            v-if="data[column.value]"
            class="avatar-sm rounded my-2"
            :style="{
              backgroundColor: data[column.value].includes('#')
                ? data[column.value]
                : `#${data[column.value]}`,
            }"
          ></div>
          <div v-else>-</div>
        </template>
        <div v-else class="text-capitalize fs-16-custom">
          {{
            column.value.includes(".")
              ? getValue(data, column.value)
              : data[column.value] || "-"
          }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>
